<script lang="ts">
    import type { PostTeaserFragment, SubmissionTeaserFragment } from '$gen/graphql';
    import { routes } from '$lib/routes';
    import { inview } from 'svelte-inview';

    export let post: PostTeaserFragment | SubmissionTeaserFragment;

    type PostCategory = Extract<
        PostTeaserFragment['postCategory'][number],
        { __typename: 'postCategory_Category' }
    >;
    type KeyImage =
        | Extract<PostTeaserFragment['keyImage'][number], { __typename: 'assets_Asset' }>
        | Extract<
              SubmissionTeaserFragment['keyImage'][number],
              { __typename: 'submissions_Asset' }
          >;

    let href = '';
    let img: KeyImage;
    let entered: boolean = false;

    $: {
        if (post?.__typename === 'posts_default_Entry') {
            href = post?.uri ? `/${post.uri}` : '';
        } else if (post?.__typename === 'exhibition_Submission') {
            href = routes.submission(post.id, post.title);
        }
    }
    $: img =
        post?.keyImage &&
        post.keyImage.length > 0 &&
        ('assets_Asset' === post.keyImage[0].__typename ||
            'submissions_Asset' === post.keyImage[0].__typename)
            ? post.keyImage[0]
            : undefined;

    const isCategory = (item): item is PostCategory =>
        (item as PostCategory).__typename === 'postCategory_Category';
</script>

{#if post}
    <article
        class="h-full max-h-[150vw] md:max-h-[95vh] lg:max-h-[80vh] xl:max-h-[56vh] 2xl:h-[74vh] 2xl:max-h-[77vh]"
    >
        <a {href} sveltekit:prefetch class="group flex h-full flex-col overflow-hidden">
            {#if img}
                <img
                    use:inview={{}}
                    on:enter={() => (entered = true)}
                    class={`h-full w-full overflow-y-hidden object-cover group-hover:scale-105 transition-all duration-500 ${
                        entered ? '' : 'blur-xl'
                    }`}
                    srcset={img.srcset ? img.srcset : ''}
                    width={img.width}
                    height={img.height}
                    src={img.url}
                    alt=""
                    loading="lazy"
                />
            {/if}
            <div class="z-10 bg-white pt-0.5 text-sm leading-4 dark:bg-black">
                <p class="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                    {post.title}
                </p>
                <ul class="flex flex-row justify-between text-blue">
                    <li>{post.date}</li>
                    {#each post.postCategory.filter(isCategory) as { title }}
                        <li>#{title}</li>
                    {/each}
                </ul>
            </div>
        </a>
    </article>
{/if}
